import React from 'react';
import { graphql } from 'gatsby';

import { ChakraProvider, Text, Flex, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import faq8Image from '../images/faqs/faq8.png';
import theme from '../utils/theme';

import Banner from '../Sections/Banner';
import Header from '../components/Header';
import Footer from '../components/Footer';
import FAQItem from '../components/FAQItem';
import Seo from '../components/SEO';


const FAQS = () => {
  const { t } = useTranslation('faqs');
  const faqs = [
    {
      question: t('faq1.question'),
      answer: [
        { text: t('faq1.answer.p1'), isItem: false },
        { text: t('faq1.answer.p2'), isItem: false },
        { text: t('faq1.answer.p3'), isItem: true },
        { text: t('faq1.answer.p4'), isItem: true, styles: { marginBottom: '1rem' } },
        { text: t('faq1.answer.p5'), isItem: false },
      ],
    },
    {
      question: t('faq2.question'),
      answer: [
        { text: t('faq2.answer.p1'), isItem: false },
        { text: t('faq2.answer.p2'), isItem: false },
      ],
    },
    {
      question: t('faq3.question'),
      answer: [
        { text: t('faq3.answer.p1'), isItem: false },
        { text: t('faq3.answer.p2'), isItem: false },
        { text: t('faq3.answer.p3'), isItem: false },
      ],
    },
    {
      question: t('faq4.question'),
      answer: [
        { text: t('faq4.answer.p1'), isItem: false },
        { text: t('faq4.answer.p2'), isItem: false },
        { text: t('faq4.answer.p3'), isItem: false },
      ],
    },
    {
      question: t('faq8.question'),
      answer: [
        { text: t('faq8.answer.p1'), isItem: false, isHtml: true },
        { text: t('faq8.answer.p2'), isItem: false },
        { text: t('faq8.answer.p3'), isItem: false },
        { text: t('faq8.answer.p4'), isItem: false, styles: { marginBottom: '0.5rem' } },
        { text: t('faq8.answer.p5'), isItem: true },
        { text: t('faq8.answer.p6'), isItem: true },
        { text: t('faq8.answer.p7'), isItem: true, styles: { marginBottom: '1rem' } },
        { text: t('faq8.answer.p8'), isItem: false },
        { text: t('faq8.answer.p9'), isItem: false },
        { text: t('faq8.answer.p10'), isItem: false },
        { text: t('faq8.answer.p11'), isItem: false, isHtml: true },
        { image: faq8Image, styles: { height: '40px', margin: '15px 0px' } },
        { text: t('faq8.answer.p12'), isItem: false, isHtml: true },
        { text: t('faq8.answer.p13'), isItem: false, isHtml: true },
        { text: t('faq8.answer.p14'), isItem: true, styles: { marginBottom: '1rem' } },
        { text: t('faq8.answer.p15'), isItem: true },
        { text: t('faq8.answer.p16'), isItem: false, isSubItem: true },
        { text: t('faq8.answer.p17'), isSubItem: true, styles: { marginBottom: '1rem' } },
        { text: t('faq8.answer.p18'), isItem: false },
      ],
    },
    {
      question: t('faq5.question'),
      answer: [{ text: t('faq5.answer.p1'), isItem: false }],
    },
    {
      question: t('faq6.question'),
      answer: [
        { text: t('faq6.answer.p1'), isItem: false },
        { text: t('faq6.answer.p2'), isItem: false },
      ],
    },
    {
      question: t('faq7.question'),
      answer: [
        { text: t('faq7.answer.p1'), isItem: false },
        { text: t('faq7.answer.p2'), isItem: false },
      ],
    },
  ];

  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Flex direction="column" alignItems="center" px={{ base: '20px', xs: '80px' }} py="96px">
        <Flex direction="column" mb="64px" textAlign="center">
          <Heading size="md" mb="20px">
            {t('faqs.title')}
          </Heading>
          <Text size="xl" color="gray.500">
            {t('faqs.subtitle')}
          </Text>
        </Flex>
        <Flex direction="column" width="100%" px={{ base: 0, md: 150, lg: 250 }}>
          {faqs.map((faq) => (
            <FAQItem key={faq.question} question={faq.question} answer={faq.answer} />
          ))}
        </Flex>
      </Flex>
      <Banner />
      <Footer />
    </ChakraProvider>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default FAQS;

export const Head = () => {
  return <Seo title="Landtoken - Preguntas frecuentes" />;
};
