import React from 'react';

import {
  Flex,
  Text,
  UnorderedList,
  ListItem,
  Image,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/react';

import { MinusIcon, AddIcon } from '@chakra-ui/icons';

type AnswerItemProps = {
  text: string;
  isItem: Boolean;
  isSubItem: Boolean;
  image: string;
  isHtml: Boolean;
  styles: {};
};

type FAQItemProps = {
  question: string;
  answer: Array<AnswerItemProps>;
};

const FAQItem = ({ question, answer }: FAQItemProps): JSX.Element => {
  const renderAnswer = (item: AnswerItemProps) => {
    if (item.isItem) {
      return (
        <UnorderedList mb="1" ml="2rem" style={item.styles}>
          <ListItem>{item.text}</ListItem>
        </UnorderedList>
      );
    }

    if (item.isSubItem) {
      return (
        <UnorderedList mb="1" ml="4rem" style={item.styles}>
          <UnorderedList>
            <ListItem>{item.text}</ListItem>
          </UnorderedList>
        </UnorderedList>
      );
    }

    if (item.image) {
      return <Image src={item.image} style={item.styles} />;
    }

    if (item.isHtml) {
      return <Text mb="4" dangerouslySetInnerHTML={{ __html: item.text }} />;
    }

    return (
      <Text mb="4" style={item.styles}>
        {item.text}
      </Text>
    );
  };

  return (
    <Accordion allowToggle allowMultiple w="100%">
      <AccordionItem borderTopWidth="0">
        {({ isExpanded }) => (
          <>
            <AccordionButton pt="4">
              <Flex w="100%" justify="space-between">
                <Text textAlign="left" color="gray.900" fontWeight="500">
                  {question}
                </Text>
                {isExpanded ? <MinusIcon fontSize="10px" /> : <AddIcon fontSize="10px" />}
              </Flex>
            </AccordionButton>
            <AccordionPanel pb="6">{answer.map((item) => renderAnswer(item))}</AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default FAQItem;
